import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_immo_1 from '../assets/images/pic_immo_1.jpg'
import pic_immo_2 from "../assets/images/pic_immo_14.jpg";
import pic_immo_3 from "../assets/images/pic_immo_3.jpg";
import pic_immo_4 from "../assets/images/pic_immo_4.jpg";
import pic_immo_5 from "../assets/images/pic_immo_5.jpg";
import pic_immo_6 from "../assets/images/pic_immo_6.jpg";
import pic_immo_7 from "../assets/images/pic_immo_7.jpg";
import pic_immo_8 from "../assets/images/pic_immo_8.jpg";
import pic_immo_13 from "../assets/images/pic_immo_13.jpg";
import pic_immo_11 from "../assets/images/pic_immo_11.jpg";
import pic_immo_360 from "../assets/images/360.jpg";
import Biographie from "../components/Biographie";

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe immobilier</title>
            <meta name="description" content="Photos immobilières Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Photos immobilières</h2>
                    </header>
                    <p>
                        Photographe dans l'immobilier, je réalise des clichés de photos d'intérieur et d'extérieur pour illustrer et valoriser votre logement.
                        <br/>Que ce soit pour vendre ou louer votre bien, des photographies immobilières de bonne qualité permettent de mettre en avant votre logement.
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <span className="image "><img src={pic_immo_1} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <p>Faire appel à un photographe professionnel c'est la garantie d'augmenter vos chances de vendre dans les meilleurs délais et au meilleur prix</p>
                        </div>
                    </div>
                </section>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <h3>Présentez vos biens de manière interactive avec une visite virtuel à 360°</h3>
                            <p>Celle-ci vous permettra de voir votre bien sous tous ses angles, grâce à une navigation fluide de pièce en pièce</p>
                            <p>Grâce à la visite virtuelle, vous pouvez visiter un bien quand et où vous le souhaitez et ce, sur tous les supports électroniques</p>
                        </div>
                    </div>
                    {/*<span className="image "><img src={pic_immo_360} alt="" /></span>*/}
                    {/*<span className="image "><img src={pic_immo_360} alt="" /></span>*/}
                    {/*<div className="livetour" data-v-4c0352cc data-v-2e899fb8>*/}
                    {/*    <iframe width="100%" height="500px" alt="livetour"*/}
                    {/*            src="https://livetour.istaging.com/27eeb835-aeb3-4a95-9bd4-0ec7360ac2b7?panobar=false&share=hidden&hideContactInfo=true&hideViewNum=true&hideLike=true&hideVRMode=true&hideGyro=true"*/}
                    {/*            frameBorder="0" allowFullScreen className="iframe-content" data-v-4c0352cc></iframe>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <iframe*/}
                    {/*        // src="https://livetour.istaging.com/969b796f-e72d-4d71-a4cd-06e06fa46375?panobar=true&share=hidden&hideContactInfo=true&hideViewNum=true&hideLike=true&hideVRMode=true&hideGyro=true"*/}
                    {/*        src="https://livetour.istaging.com/969b796f-e72d-4d71-a4cd-06e06fa46375"*/}
                    {/*        frameBorder="0" allowFullScreen className="iframe-content" data-v-4c0352cc>*/}
                    {/*    </iframe>*/}
                    {/*</div>*/}
                    <div>
                        <iframe alt="livetour" height="300px" width="500px"
                                src="https://livetour.istaging.com/746b5559-3c80-4305-914f-3dd796d6aff9"
                                frameBorder="0" allowFullScreen className="iframe-content"></iframe>
                    </div>
                </section>
            </section>
            <section id="one">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">
                            <div className="col-4"><span className="image fit"><img src={pic_immo_2} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_3} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_4} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_5} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_6} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_7} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_8} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_13} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_immo_11} alt="" /></span></div>
                        </div>
                    </div>
                </div>
            </section>
            <Biographie/>
        </div>

    </Layout>
)

export default Landing
